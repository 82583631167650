import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';

import { MeBasicAlertComponent } from './me-basic-alert.component';
import { MeBasicAlertService } from './me-basic-alert.service';
import { MeIconsProviderModule } from '../me-icons.module';
@NgModule({
  imports: [CommonModule, RouterModule, MatSnackBarModule, MeIconsProviderModule],
  exports: [MeBasicAlertComponent],
  declarations: [MeBasicAlertComponent],
  providers: [MeBasicAlertService]
})
export class MeBasicAlertModule { }
