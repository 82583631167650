import { Observable, catchError, map, of } from 'rxjs';
import { inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanDeactivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UtilityService } from '../services/utility.service';
import { AuthService } from '../services/auth.service';
import { AuthWebService } from 'src/app/modules/login/auth-web.service';
import { isPlatformBrowser } from '@angular/common';
import { SigninRoute } from 'src/app/constant/constants';

export const authGuard: CanActivateFn = (route, state) => {

  const _utilityService = inject(UtilityService);
  const authWebService = inject(AuthWebService);
  const router = inject(Router);
  const authservice = inject(AuthService);
  const platformId = inject(PLATFORM_ID);

  if (isPlatformBrowser(platformId)) {
    if (authservice.user) {
      return true
    } else if (_utilityService.getAuthToken()) {
      return authWebService.getCurrentUser().pipe(
        map((res) => {
          if (res.data) {
            authservice.user = res.data;
            return true;
          } else {
            _utilityService.logoutRemoveToken();
            return false;
          }
        }),
        catchError(() => {
          _utilityService.logoutRemoveToken();
          // this.router.navigate([this.redirectToNotAuthorizedPage]);
          return of(false);
        })
      )
    } else {
      _utilityService.logoutRemoveToken();
      router.navigate([SigninRoute]);
      return false;
    }
  } else return true;
};

// export interface CanComponentDeactivate {
//   canDeactivate: () => Observable<boolean> | boolean;
// }

// export const deactivateAuthGuard: CanDeactivateFn<CanComponentDeactivate> = (component,
//   currentRoute,
//   currentState,
//   nextState) => {
//   debugger;
//   const platformId = inject(PLATFORM_ID);
//   const _utilityService = inject(UtilityService);

//   if (isPlatformBrowser(platformId)) {
//     if (!_utilityService.getRememberMe()) {
//       _utilityService.logoutRemoveToken();
//     }
//   }
//   return true;
// }