export const environment = {
  production: true,
  tokenKey: 'wmhhmb',
  rememberMeKey: 'loggedIn',
  API_KEY: 'WmHhFreshWOrK',
  ssr_port: 6001,

  API_BASE_PATH: 'https://admin.dev.cmhamb.symbicore.com/v1/',
  url: "https://pathminder.dev.symbicore.com/"
  // API_BASE_PATH: "http://localhost:4567/v1/",
  // url: 'http://localhost:4200',
};
