import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SessionKey } from '../../constant/enums';
import { FilterCriteriaItemI, SelectedFilterCriteriaI } from '../models/filter-criteria.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FilterDataStoreService {

  TopicsData: any;
  private selectedAnswerid: string;

  private readonly _selectedFilterCriteria = new BehaviorSubject<SelectedFilterCriteriaI[]>([]);
  readonly selectedFilterCriteria$ = this._selectedFilterCriteria.asObservable();

  private readonly _resetelectedFilterCriteria = new BehaviorSubject<boolean>(false);
  readonly resetelectedFilterCriteria$ = this._resetelectedFilterCriteria.asObservable();

  private readonly _searchbarFilterCriteria = new BehaviorSubject<string>(undefined);
  readonly searchbarFilterCriteria$ = this._searchbarFilterCriteria.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }


  get selectedFilterCriteria(): SelectedFilterCriteriaI[] {
    let data = "";
    if (isPlatformBrowser(this.platformId)) {
      data = localStorage.getItem(SessionKey.FilterCriteria);
      return <SelectedFilterCriteriaI[]>JSON.parse(data);
    }
    return [];
    // return this._selectedFilterCriteria.getValue();
  }

  set selectedFilterCriteria(data: SelectedFilterCriteriaI[]) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(SessionKey.FilterCriteria, JSON.stringify(data));
      this._selectedFilterCriteria.next(data);
    }
  }


  get resetelectedFilterCriteria(): boolean {
    return this._resetelectedFilterCriteria.getValue();
  }

  set resetelectedFilterCriteria(data) {
    this._resetelectedFilterCriteria.next(data);
  }


  get searchbarFilterCriteria(): string {
    let data = "";
    if (isPlatformBrowser(this.platformId)) {
      data = localStorage.getItem(SessionKey.SaveFilterCriteria);
      return JSON.parse(data);
    }
    return undefined;
  }

  set searchbarFilterCriteria(data) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(SessionKey.SaveFilterCriteria, JSON.stringify(data));
      this._searchbarFilterCriteria.next(data);
    }
  }


  getselectedAnswerId() {
    return this.selectedAnswerid;
  }

  setselectedAnswerId(data) {
    this.selectedAnswerid = data;
  }


}
