import { HeartFill } from './svg/heart-fill';
import { CheckCircle } from './svg/check-circle';
import { AlertTriangle } from './svg/alert-triangle';
import { AlertOctagon } from './svg/alert-octagon';
import { Book } from './svg/book';
import { Close } from './svg/close';
import { ErrorIcon } from './svg/error-icon';
import { Eye } from './svg/eye';
import { EyeOff } from './svg/eye-off';
import { HelpCircle } from './svg/help-circle';
import { Rectangle405 } from './svg/rectangle-405';
import { Time } from './svg/time';
import { PeopleCircle } from './svg/people-circle';
import { AddCircleSharp } from './svg/add-circle-sharp';
import { PersonCircleOutline } from './svg/person-circle-outline';
import { LogOut } from './svg/log-out';
import { Rectangle405Fill } from './svg/rectangle-405-fill';
import { AddCircleSharpBlue } from './svg/add-circle-sharp-blue';
import { Add } from './svg/add';
import { EditPencil } from './svg/edit-pencil';
import { Trash } from './svg/trash';
import { SelfHelpresources } from './svg/self-helpresources';
import { HospitalhealthUnit } from './svg/hospitalhealth-unit';
import { PeerSupport } from './svg/peer-support';
import { CounsellingAndTherapy } from './svg/counselling-and-therapy';
import { PhoneAndChatSupport } from './svg/phone-and-chat-support';
import { CommunityBasedServices } from './svg/community-based-services';
import { MenuOutline } from './svg/menu-outline';
import { VideoCam } from './svg/video-cam';
import { Audio } from './svg/audio';
import { Calender } from './svg/calender';
import { People } from './svg/people';
import { Wifi } from './svg/wifi';
import { ArrowBack } from './svg/arrow-back';
import { ArrowIconNext } from './svg/arrow-icon-next';
import { ArrowIconPrev } from './svg/arrow-icon-prev';
import { CloseWhite } from './svg/close-white';
import { ModelClose } from './svg/model-close';
import { Heart } from './svg/heart';

export const allIcons = {
  Eye,
  EyeOff,
  Time,
  ErrorIcon,
  Close,
  Rectangle405,
  Book,
  PeopleCircle,
  HelpCircle,
  AddCircleSharp,
  PersonCircleOutline,
  LogOut,
  Rectangle405Fill,
  AddCircleSharpBlue,
  Add,
  EditPencil,
  Trash,
  SelfHelpresources,
  HospitalhealthUnit,
  PeerSupport,
  CounsellingAndTherapy,
  PhoneAndChatSupport,
  CommunityBasedServices,
  MenuOutline,
  VideoCam,
  Audio,
  Calender,
  People,
  Wifi,
  ArrowBack,
  ArrowIconNext,
  ArrowIconPrev,
  AlertOctagon,
  AlertTriangle,
  CheckCircle,
  CloseWhite,
  ModelClose,
  Heart,
  HeartFill
};
