export const HelpCircle = `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
<g clip-path="url(#clip0_1273_13543)">
  <path d="M11.5 0C5.15104 0 0 5.15104 0 11.5C0 17.849 5.15104 23 11.5 23C17.849 23 23 17.849 23 11.5C23 5.15104 17.849 0 11.5 0ZM11.1406 18.2083C10.9037 18.2083 10.6721 18.1381 10.4751 18.0064C10.2781 17.8748 10.1246 17.6877 10.0339 17.4688C9.94323 17.2499 9.9195 17.0091 9.96573 16.7767C10.0119 16.5443 10.126 16.3309 10.2936 16.1634C10.4611 15.9958 10.6745 15.8817 10.9069 15.8355C11.1393 15.7893 11.3802 15.813 11.599 15.9037C11.8179 15.9944 12.005 16.1479 12.1367 16.3449C12.2683 16.5419 12.3385 16.7735 12.3385 17.0104C12.3385 17.3281 12.2123 17.6328 11.9877 17.8575C11.763 18.0821 11.4583 18.2083 11.1406 18.2083ZM13.1435 12.099C12.1726 12.7506 12.0391 13.3478 12.0391 13.8958C12.0391 14.1182 11.9507 14.3315 11.7935 14.4888C11.6362 14.646 11.4229 14.7344 11.2005 14.7344C10.9781 14.7344 10.7648 14.646 10.6076 14.4888C10.4503 14.3315 10.362 14.1182 10.362 13.8958C10.362 12.5835 10.9657 11.5401 12.208 10.7058C13.3628 9.93073 14.0156 9.43958 14.0156 8.35966C14.0156 7.62534 13.5964 7.06771 12.7285 6.65503C12.5242 6.55799 12.0696 6.46336 11.5102 6.46995C10.8082 6.47893 10.2632 6.64664 9.84328 6.98445C9.05146 7.62174 8.98438 8.31534 8.98438 8.32552C8.97907 8.43564 8.95212 8.54363 8.90507 8.64334C8.85803 8.74304 8.7918 8.83251 8.71018 8.90662C8.62856 8.98073 8.53314 9.03804 8.42938 9.07527C8.32561 9.11251 8.21552 9.12894 8.1054 9.12363C7.99528 9.11832 7.88729 9.09138 7.78759 9.04433C7.68788 8.99728 7.59842 8.93106 7.52431 8.84944C7.4502 8.76782 7.39289 8.6724 7.35565 8.56863C7.31841 8.46487 7.30198 8.35478 7.30729 8.24466C7.31388 8.09911 7.4151 6.78799 8.79091 5.68112C9.50427 5.10732 10.4117 4.80904 11.4862 4.79586C12.2469 4.78688 12.9615 4.91565 13.446 5.14445C14.8961 5.83026 15.6927 6.97367 15.6927 8.35966C15.6927 10.3859 14.3385 11.2958 13.1435 12.099Z" fill="currentColor"/>
</g>
<defs>
  <clipPath id="clip0_1273_13543">
    <rect width="23" height="23" fill="currentColor"/>
  </clipPath>
</defs>
</svg>`;
