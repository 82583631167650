import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { HttpInterceptorProviders } from './Interceptors';
import { AuthWebService } from '../modules/login/auth-web.service';
import { GlobalService } from './services/global.service';
import { LoaderModule } from '../shared/loader/loader.module';
import { MeBasicAlertModule } from '../shared/me-basic-alert/me-basic-alert.module';
import { MeLoadingDirective } from './directives/me-component-loader.directive';
import { throwIfAlreadyLoaded } from './guard/module-import.guard';
import { ModulesModule } from '../modules/modules.module';

@NgModule({
  declarations: [MeLoadingDirective],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoaderModule,
    MeBasicAlertModule,
    ModulesModule
  ],
  providers: [
    HttpInterceptorProviders,
    AuthWebService,
    GlobalService,
    {
      provide: MatSnackBarConfig,
      useValue: {
        duration: 70000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      },
    },
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded<CoreModule>(parentModule, 'CoreModule');
  }
}
