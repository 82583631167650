import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../core/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/features.module').then(
        (m) => m.FeaturesModule
      ),
  },
  {
    path: '',
    loadChildren: () => import("./login/login.module").then((m) => m.LoginModule)
  },
  {
    path: 'account',
    loadChildren: () => import("./account/account.module").then((m) => m.AccountModule),
    canActivate: [authGuard],
    // canDeactivate: [deactivateAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModulesRoutingModule { }
