<div class="basic-alert box-sizing-border-box {{ data.mode }}">
  <div class="icon-side">
    <!-- <me-icons *ngIf="data.mode === 'regular'" name="activity"></me-icons> -->
    <me-icons *ngIf="data.mode === 'success'" name="check-circle"></me-icons>
    <me-icons *ngIf="data.mode === 'error'" name="alert-octagon"></me-icons>
    <me-icons *ngIf="data.mode === 'warning'" name="alert-triangle"></me-icons>

    <!-- <i-feather *ngIf="data.mode === 'regular'" name="activity"></i-feather>
    <i-feather *ngIf="data.mode === 'success'" name="check-circle"></i-feather>
    <i-feather *ngIf="data.mode === 'error'" name="alert-octagon"></i-feather>
    <i-feather *ngIf="data.mode === 'warning'" name="alert-triangle"></i-feather> -->
  </div>

  <div class="text-side">
    <div class="text-header">{{ data.title }}</div>
    <div class="text-content">{{ data.content }}</div>
  </div>

  <div class="link-close-side">
    <a *ngIf="data.linkText && data.externalLink" [href]="data.externalLink" target="_blank" rel="noopener noreferrer"
      (click)="customEvent('exit')">{{ data.linkText }}</a>

    <a *ngIf="data.linkText && data.internalLink" [href]="data.internalLink" (click)="customEvent('exit')">{{
      data.linkText }}</a>

    <me-icons name="close-white" (click)="customEvent('exit')"></me-icons>
    <!-- <i-feather name="x" (click)="customEvent('exit')"></i-feather> -->
  </div>
</div>
