import { TopicDescriptionModel } from "../modules/features/resource-list/resource.model";

export const siteTitle = "Pathminder - Guiding your path to well-being";
export const siteDescription = "We are here to help you find the support that fits your needs";

export const queStressedAndAnxious = "stressed-and-anxious";
export const queManagingMySymptoms = "managing-symptoms-and-improving-mental-health";
export const queFindAvailableSupports = "find-available-supports";

export const GETHELPNOWURL = "https://mb.211.ca/";

export const urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

export const PAGE_START = 1;
export const PROPERTY_PAGINATION = 10;
export const PAGE_OPTIONS_12 = {
   page: PAGE_START,
   limit: 12,
};
export const PAGE_OPTIONS = {
   page: PAGE_START,
   limit: PROPERTY_PAGINATION,
};

export const shuffle = <T>(array: T[]) => {
   for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
   }
   return array;
};

export const AccountInfoRoute = "account/info";
export const SignUpRoute = "/signup";
export const ForgotPasswordRoute = "/forgotpassword";
export const SigninRoute = "/signin";

export const States = [{ name: "Manitoba", value: "MB" }];

export const DefaultTopicDescription: TopicDescriptionModel[] = [{
   _id: "",
   title: "Explore Resources",
   description: "<p>We all have mental health. Taking care of your mental health is just as important as taking care of your physical health.</p><p>The resources below can help you learn more about your mental health and well-being and connect you with the people who can support you on your journey.</p>",
   show: true
}]