export const PeerSupport = `<svg class="cl-icon" width="23" height="23" viewBox="0 0 23 23" fill="none"
xmlns="http://www.w3.org/2000/svg">
<circle cx="11.5008" cy="11.9998" r="3.45" fill="currentColor" />
<circle cx="11.5008" cy="3.95" r="2.875" stroke="currentColor" stroke-width="1.15" />
<circle cx="19.5496" cy="9.7" r="2.875" stroke="currentColor" stroke-width="1.15" />
<circle cx="16.1004" cy="18.9002" r="2.875" stroke="currentColor" stroke-width="1.15" />
<circle cx="6.89922" cy="18.9002" r="2.875" stroke="currentColor" stroke-width="1.15" />
<circle cx="3.45" cy="9.7" r="2.875" stroke="currentColor" stroke-width="1.15" />
<line x1="11.4992" y1="9.7002" x2="11.4992" y2="6.2502" stroke="currentColor" stroke-width="1.15" />
<line x1="14.2088" y1="11.0013" x2="16.5331" y2="10.2997" stroke="currentColor" stroke-width="1.15" />
<line x1="13.1375" y1="13.9943" x2="14.4273" y2="16.0512" stroke="currentColor" stroke-width="1.15" />
<line y1="-0.575" x2="2.42784" y2="-0.575"
  transform="matrix(-0.531255 0.847212 0.847212 0.531255 10.4883 14.2998)" stroke="currentColor"
  stroke-width="1.15" />
<line y1="-0.575" x2="2.42784" y2="-0.575"
  transform="matrix(-0.957334 -0.288983 -0.288983 0.957334 8.07422 11.5518)" stroke="currentColor"
  stroke-width="1.15" />
</svg>`;