import { Component, EventEmitter, Inject, Injector, OnDestroy, Output } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { MeBasicAlertI } from './me-basic-alert.interface';

@Component({
  templateUrl: './me-basic-alert.component.html',
  styleUrls: ['./me-basic-alert.component.scss']
})
export class MeBasicAlertComponent implements OnDestroy {
  componentVersion: string = '0.0.1';
  @Output() eventOccurs: EventEmitter<{ eventName: string }> = new EventEmitter();

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: MeBasicAlertI, injector: Injector) {
  }

  customEvent(eventName: string): void {
    this.eventOccurs.emit({ eventName });
  }

  ngOnDestroy() {
  }
}
