import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserModel } from '../models/user.model';
import { UserType } from 'src/app/constant/enums';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isSuperAdmin: boolean = false;

  private readonly _user = new BehaviorSubject<UserModel | null>(null);
  readonly user$ = this._user.asObservable();

  private readonly _invitedOrguser = new BehaviorSubject<UserModel | null>(null);
  readonly invitedOrguser$ = this._invitedOrguser.asObservable();

  constructor() { }

  get user(): UserModel | null {
    return this._user.getValue();
  }

  set user(data: UserModel | null) {
    this.isSuperAdmin = data.userType == UserType.SuperAdmin;
    this._user.next(data);
  }


  get invitedOrguser(): UserModel | null {
    return this._invitedOrguser.getValue();
  }

  set invitedOrguser(data: UserModel | null) {
    this.isSuperAdmin = data.userType == UserType.SuperAdmin;
    this._invitedOrguser.next(data);
  }
}
