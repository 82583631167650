import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { GET_AGEGROUP, GET_PAGE_CONTENT, GET_PRIORITYDEMOGRAHICS, GET_PROGRAMSBYNAME, GET_REGION, GET_SETTINGTABS, GET_STORIESOFHOPE, GET_TOPICS } from "../../constant/urls";
import { ApiResponse } from "../models/api.interface";
import { Observable } from "rxjs";

@Injectable()
export class DataService {

   constructor(private httpService: HttpService) { }

   getIntroductionContent(slug: string): Observable<ApiResponse<any>> {
      const request = {
         pageSlug: slug,
      };

      // return pageContentMaster.find(x => x.slug == request.pageSlug);
      return this.httpService.get<ApiResponse<any>>(GET_PAGE_CONTENT, { ...request });
   }

   getAgeGroupFilter() {
      return this.httpService.get(GET_AGEGROUP);
   }

   getRegionFilter() {
      return this.httpService.get(GET_REGION);
   }

   getPriorityDemograhicsFilter() {
      return this.httpService.get(GET_PRIORITYDEMOGRAHICS);
   }

   getSettingTabsFilter() {
      return this.httpService.get(GET_SETTINGTABS);
   }

   getTopicsFilter(): Observable<ApiResponse<any>> {
      return this.httpService.get<ApiResponse<any>>(GET_TOPICS);
   }

   getProgramData(value): Observable<ApiResponse<any>> {
      return this.httpService.get<ApiResponse<any>>(GET_PROGRAMSBYNAME, { ...value })
   }

   getStoriesofhopeContent(): Observable<ApiResponse<any>> {
      return this.httpService.get<ApiResponse<any>>(GET_STORIESOFHOPE);
    }
   // getRegionFilter
}