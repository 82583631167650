import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";

@Injectable({
   providedIn: "root",
})
export class UtilityService implements OnDestroy {

   constructor(
      @Inject(PLATFORM_ID) private platformId: Object,
      private cookieService: CookieService
   ) {

   }

   removeAuthToken() {
      if (isPlatformBrowser(this.platformId)) {
         this.cookieService.delete(environment.tokenKey, "/");
      }
   }

   setAuthToken(token) {
      let expirytime: number | Date;
      if (isPlatformBrowser(this.platformId)) {
         if (!this.getRememberMe()) expirytime = 0;
         else expirytime = this.getOneMonthDate();
         this.cookieService.set(environment.tokenKey, token, expirytime, "/");
      }
   }

   getAuthToken() {
      if (isPlatformBrowser(this.platformId)) {
         return this.cookieService.get(environment.tokenKey);
      }
   }

   removeRememberMe() {
      if (isPlatformBrowser(this.platformId)) {
         this.cookieService.delete(environment.rememberMeKey, "/");
      }
   }
   setRememberMe(flag) {
      if (isPlatformBrowser(this.platformId)) {
         this.cookieService.set(environment.rememberMeKey, flag, this.getOneMonthDate(), "/");
      }
   }
   getRememberMe() {
      if (isPlatformBrowser(this.platformId)) {
         return this.cookieService.get(environment.rememberMeKey) == "true";
      }
   }

   clearStorage() {
      if (isPlatformBrowser(this.platformId)) {
         localStorage.clear();
      }
   }

   convertmilisectomin(milisec) {
      const milliseconds = milisec;
      const minutes = (milliseconds / 1000 / 60) % 60;
      return minutes
   }

   convertmilisectosec(milisec) {
      const milliseconds = milisec;
      const seconds = (milliseconds / 1000);
      return seconds
   }

   afterLoginSuccess(data: any, isRememberMe: boolean) {
      this.setRememberMe(isRememberMe);
      this.setAuthToken(data.authToken);
   }

   logoutRemoveToken() {
      this.removeAuthToken();
      this.removeRememberMe();
   }

   private getOneMonthDate() {
      const today = new Date();
      today.setMonth(today.getMonth() + 1);
      return today;
   }

   ngOnDestroy(): void {

   }
}