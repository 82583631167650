import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalService } from '../services/global.service';
import { MeBasicAlertService } from 'src/app/shared/me-basic-alert/me-basic-alert.service';


@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  manualHandledPaths: string[] = ['/auth/password-reset-token', '/events/batch', '/server/info'];

  constructor(private globalService: GlobalService,
    private basicAlertService: MeBasicAlertService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isNotManualHandled(request.url)) {
      return next.handle(request).pipe(
        catchError((error) => {
          this.globalService.deactivateLoader();
          this.basicAlertService.openBasicAlert({
            mode: 'error',
            title: error.error?.title ? error.error?.title : error.name,
            content: error.error?.message ? error.error?.message : error.message
          });
          return throwError(error);
        })
      );
    }
    return next.handle(request);
  }

  private isNotManualHandled(path: string): boolean {
    return this.manualHandledPaths.every((elem) => path.indexOf(elem) === -1);
  }
}
