export enum STATIC_CONTENT_CMS {
   ABOUT_US = "about-us",
   HOME_PAGE = "home-page",
   STORIES_OF_HOPE = "stories-of-hope",
   EXPLORE_PATHMINDER = "explore-pathminder",
   WELCOMETOPATHMINDER = "welcome-to-pathminder"
}

export enum Setting {
   SelfHelpORResources = "Self-help/Resources",
   HospitalORHealthUnit = "Hospital/Health Unit",
   PhoneORChat = "Phone and Chat Support",
   CounsellingAndTherapy = "Counselling and Therapy",
   PeerSupport = "Peer Support",
   CommunityBased = "Community-based Services"
}

export enum ServiceModality {
   Virtual = "Virtual",
   In_person = "In_person"
}

export enum SessionKey {
   FilterCriteria = "FilterCriteria",
   SaveFilterCriteria = "SaveFilterCriteria",
   UserDetail = "user_detail"
}

export enum FilterTitle {
   topics = "Topics",
   agegroup = "Age Group",
   groupidentity = "Group Identity",
   locations = "Locations",
   Searchfor = "Searching For"
}

export enum FilterDiffTitle {
   topics = "Topics",
   agegroup = "I'm a...",
   groupidentity = "Who's also...",
   locations = "I live in...",
   Searchfor = "I am searching for..."
}

export enum FilterSlug {
   topics = "topics",
   agegroup = "agegroup",
   groupidentity = "groupidentity",
   locations = "locations"
}

export enum UserType {
   SuperAdmin = 0,
   Admin = 1,
   user = 2
}

export enum PROGRAMSTATUS {
   Approved = "Approved",
   InReview = "InReview",
   Rejected = "Rejected"
}

export const Featured = "Featured";