import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModulesComponent } from './modules/modules.component';


const routes: Routes = [
  {
    path: '',
    component: ModulesComponent,
    loadChildren: () =>
      import('./modules/modules.module').then(
        (m) => m.ModulesModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
