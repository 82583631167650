import { Component, ElementRef, Input, Inject, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Icons } from './me-icon.provider';
import { upperCamelCase } from './me-icon.utils';

@Component({
  selector: 'me-icons',
  templateUrl: './me-icons.component.html',
  styleUrls: ['./me-icons.component.scss']
})
export class MeIconsComponent implements OnChanges {
  @Input() name!: string;

  constructor(
    @Inject(ElementRef) private elem: ElementRef,
    @Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef,
    @Inject(Icons) private icons: Icons
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const icons = Object.assign({}, ...(this.icons as object[]));
    const svg = icons[upperCamelCase(changes.name.currentValue)] || '';

    if (!svg) {
      console.warn(`Icon not found: ${changes.name.currentValue}`);
    }

    this.elem.nativeElement.innerHTML = svg;
    this.changeDetector.markForCheck();
  }
}
