import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthWebService } from './modules/login/auth-web.service';
import { HttpInterceptorProviders } from './core/Interceptors';
import { MeLoadingDirective } from './core/directives/me-component-loader.directive';
import { LoaderModule } from './shared/loader/loader.module';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { MeBasicAlertModule } from './shared/me-basic-alert/me-basic-alert.module';
import { GlobalService } from './core/services/global.service';
import { CoreModule } from './core/core.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    CoreModule
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
