export const USER = "user";
export const HOME = "home";
export const QUE = "que";
export const PROGRAM = "program";
export const ADMIN = "admin";

export const GET_PAGE_CONTENT = `${HOME}/get-page-content`;
export const GET_STORIESOFHOPE = `${HOME}/get-storiesofhope`;

export const GET_QUESLIST = `${QUE}/get-quelist`;
export const GET_QUE = `${QUE}/get-que`;
export const GET_REGION = `${QUE}/getregion`;
export const GET_AGEGROUP = `${QUE}/getagegroup`;
export const GET_PRIORITYDEMOGRAHICS = `${QUE}/getPriorityDemograhics`;
export const GET_SETTINGTABS = `${QUE}/getsettingtabs`;
export const GETSUBSETTINGTABS = `${QUE}/getsubsettingtabs`;
export const GET_TOPICS = `${QUE}/getTopics`;

export const GET_PROGRAMLIST = `${PROGRAM}/get-programlist`;
export const GET_PROGRAMBYID = `${PROGRAM}/get-program`;
export const GET_PROGRAMSBYNAME = `${PROGRAM}/get-programsByName`;
export const GET_ACCOUNTPROGRAMS = `${PROGRAM}/get-accountprograms`;
export const GET_ALL_PROGRAMLIST = `${PROGRAM}/get-all-programlist`;
export const CREATE_PROGRAM = `${PROGRAM}/create-program`;
export const UPDATE_PROGRAM = `${PROGRAM}/update-program`;
export const UPDATE_PROGRAMSTATUS = `${PROGRAM}/update-programStatus`;
export const DELETE_PROGRAM = `${PROGRAM}/delete-program`;
export const GET_FEATUREDPROGRAMS = `${PROGRAM}/get-featuredprograms`;
export const UPDATEFEATURED = `${PROGRAM}/updateFeatured`;

export const CHECKACCOUNTEXIST = `${USER}/check-accountexist`;
export const SIGNUP = `${USER}/signup`;
export const INVITEDSIGNUP = `${USER}/invitedSignup`;
export const LOGIN = `${USER}/login`;
export const VERIFYTOKEN = `${USER}/verify-token`;
export const VERIFYLOGINTOKEN = `${USER}/verify-loginlink-token`;
export const VERIFYORGLOGINTOKEN = `${USER}/verify-orglink-token`;
export const PROFILE = `${USER}/profile`;
export const ACCOUNT = `${USER}/account`;
export const ACCOUNTLIST = `${USER}/account-list`;
export const UPDATEACCOUNT = `${USER}/updateaccount`;
export const USERPROFILE = `${USER}/getuser`;
export const GETACCOUNTUSER = `${USER}/getAccountuser`;
export const UPDATEUSER = `${USER}/updateUser`;
export const ADDTEAMMEMBER = `${USER}/addTeamMember`;
export const DELETE_TEAMMEMBER = `${USER}/delete-teamMember`;
export const DELETE_ORGANIZATION = `${USER}/delete-organization`;
export const UPDATEUSERADMIN = `${USER}/updateUserAdmin`;
export const INVITEORGANIZATION = `${USER}/inviteOrganization`;

//

export const INSERTADDRESS = `${USER}/insertaddress`;
export const FORGOTPASSWORD = `${USER}/forgot-password`;
export const RESETPASSWORD = `${USER}/reset-password`;
export const CREATEPASSWORD = `${USER}/create-password`;
export const UPDATEACCOUNTPROFILE = `${USER}/updateaccountprofile`;
export const DELETEACCOUNTPROFILE = `${USER}/deleteaccountprofile`;
export const UPLOADFILE = `${USER}/uploadFile`;
export const CHECKPASSWORD = `${USER}/check-password`;

export const GETSELF_ASSESSMENTQUES = `${ADMIN}/self-assessmentques`;