import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CHECKACCOUNTEXIST, CREATEPASSWORD, FORGOTPASSWORD, INVITEDSIGNUP, LOGIN, PROFILE, RESETPASSWORD, SIGNUP, VERIFYLOGINTOKEN, VERIFYORGLOGINTOKEN, VERIFYTOKEN } from 'src/app/constant/urls';
import { ApiResponse } from 'src/app/core/models/api.interface';
import { AuthModel } from 'src/app/core/models/auth/auth.model';
import { UserModel } from 'src/app/core/models/user.model';
import { HttpService } from 'src/app/core/services/http.service';

@Injectable()
export class AuthWebService {

  constructor(private httpService: HttpService) { }

  isAccountNameAvailable(name: string): Observable<ApiResponse<boolean>> {
    const request = {
      organizationName: name,
    };
    return this.httpService.get<ApiResponse<boolean>>(CHECKACCOUNTEXIST, { ...request });
  }

  login(param: any): Observable<ApiResponse<AuthModel>> {
    return this.httpService.post<AuthModel>(LOGIN, param);
  }

  signup(param: any): Observable<ApiResponse<any>> {
    return this.httpService.post<any>(SIGNUP, param);
  }

  invitedsignup(param: any): Observable<ApiResponse<any>> {
    return this.httpService.post<any>(INVITEDSIGNUP, param);
  }

  verifyToken(request: any): Observable<ApiResponse<any>> {
    return this.httpService.get<ApiResponse<any>>(VERIFYTOKEN, { ...request });
  }

  verifyLoginLinkToken(request: any): Observable<ApiResponse<any>> {
    return this.httpService.get<ApiResponse<any>>(VERIFYLOGINTOKEN, { ...request });
  }

  verifyOrgLoginLinkToken(request: any): Observable<ApiResponse<any>> {
    return this.httpService.get<ApiResponse<any>>(VERIFYORGLOGINTOKEN, { ...request });
  }

  getCurrentUser(): Observable<ApiResponse<UserModel>> {
    return this.httpService.get<ApiResponse<UserModel>>(PROFILE);
  }

  forgotPassword(params: any): Observable<ApiResponse<any>> {
    return this.httpService.patch(FORGOTPASSWORD, params)
  }

  resetPassword(params: any): Observable<ApiResponse<any>> {
    return this.httpService.patch(RESETPASSWORD, params)
  }

  createPassword(params: any): Observable<ApiResponse<any>> {
    return this.httpService.patch(CREATEPASSWORD, params)
  }
}
