export const People = `<svg class="cl-icon" width="22" height="23" viewBox="0 0 22 23" fill="none"
xmlns="http://www.w3.org/2000/svg">
<path
  d="M14.8951 13.5757C13.8254 12.6249 12.444 12.0996 11.0127 12.0996C9.58146 12.0996 8.19997 12.6249 7.13026 13.5757C7.03987 13.654 6.96592 13.7494 6.91271 13.8564C6.8595 13.9634 6.82808 14.08 6.82028 14.1992C6.81248 14.3185 6.82846 14.4382 6.86729 14.5512C6.90612 14.6643 6.96702 14.7685 7.04646 14.8578C7.1259 14.9471 7.22229 15.0197 7.33004 15.0715C7.43779 15.1232 7.55475 15.1531 7.67412 15.1592C7.79349 15.1654 7.9129 15.1478 8.02541 15.1074C8.13792 15.0671 8.24129 15.0048 8.32952 14.9241C9.06885 14.267 10.0236 13.9041 11.0127 13.9041C12.0018 13.9041 12.9566 14.267 13.6959 14.9241C13.8748 15.0831 14.1096 15.1646 14.3485 15.1505C14.5875 15.1365 14.8111 15.028 14.9701 14.8491C15.1292 14.6702 15.2106 14.4354 15.1965 14.1965C15.1825 13.9575 15.0741 13.7339 14.8951 13.5749V13.5757Z"
  fill="currentColor" />
<path
  d="M11.0124 8.39356C8.5897 8.39022 6.25695 9.31103 4.48974 10.9683C4.40026 11.0484 4.32769 11.1456 4.27628 11.2542C4.22486 11.3627 4.19565 11.4805 4.19034 11.6005C4.18502 11.7205 4.20373 11.8403 4.24535 11.953C4.28697 12.0657 4.35067 12.1689 4.43272 12.2567C4.51477 12.3444 4.6135 12.4149 4.72315 12.464C4.83279 12.5131 4.95112 12.5397 5.07122 12.5425C5.19131 12.5452 5.31074 12.524 5.4225 12.4799C5.53427 12.4359 5.63612 12.37 5.72208 12.2861C7.15701 10.9446 9.04802 10.1983 11.0124 10.1983C12.9768 10.1983 14.8678 10.9446 16.3027 12.2861C16.3887 12.37 16.4905 12.4359 16.6023 12.4799C16.714 12.524 16.8335 12.5452 16.9536 12.5425C17.0737 12.5397 17.192 12.5131 17.3016 12.464C17.4113 12.4149 17.51 12.3444 17.5921 12.2567C17.6741 12.1689 17.7378 12.0657 17.7794 11.953C17.8211 11.8403 17.8398 11.7205 17.8345 11.6005C17.8291 11.4805 17.7999 11.3627 17.7485 11.2542C17.6971 11.1456 17.6245 11.0484 17.535 10.9683C15.7678 9.31103 13.4351 8.39022 11.0124 8.39356Z"
  fill="currentColor" />
<path
  d="M19.8508 8.0558C17.418 5.88517 14.2716 4.68555 11.0113 4.68555C7.7509 4.68555 4.60451 5.88517 2.17174 8.0558C2.08031 8.1339 2.00541 8.22948 1.95144 8.33694C1.89748 8.4444 1.86554 8.56156 1.85749 8.68153C1.84945 8.80151 1.86546 8.92189 1.9046 9.03559C1.94374 9.14929 2.0052 9.25402 2.08539 9.34362C2.16557 9.43323 2.26286 9.50591 2.37153 9.55738C2.4802 9.60886 2.59807 9.6381 2.7182 9.64337C2.83833 9.64865 2.9583 9.62987 3.07107 9.58812C3.18384 9.54638 3.28713 9.48252 3.37486 9.40029C5.47674 7.52572 8.1947 6.48978 11.0111 6.48978C13.8274 6.48978 16.5454 7.52572 18.6472 9.40029C18.8256 9.55983 19.0601 9.64197 19.2991 9.62863C19.5381 9.6153 19.7619 9.50758 19.9215 9.32917C20.081 9.15077 20.1632 8.91629 20.1498 8.67733C20.1365 8.43836 20.0288 8.21448 19.8504 8.05494L19.8508 8.0558Z"
  fill="currentColor" />
<path
  d="M11.0117 18.7793C11.7711 18.7793 12.3867 18.1637 12.3867 17.4043C12.3867 16.6449 11.7711 16.0293 11.0117 16.0293C10.2523 16.0293 9.63672 16.6449 9.63672 17.4043C9.63672 18.1637 10.2523 18.7793 11.0117 18.7793Z"
  fill="currentColor" />
</svg>`;