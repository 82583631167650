import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { FilterDataStoreService } from '../core/services/filter-data-store.service';
import { GlobalService } from '../core/services/global.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit {

  isLoading: boolean = true;

  private _unsubscribeOnDestroy$: Subject<void> = new Subject();


  constructor(
    private filterService: DataService,
    private filterDataStoreService: FilterDataStoreService,
    private globalService: GlobalService,
    private crf: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.globalService.isActivatedLoader$.pipe(takeUntil(this._unsubscribeOnDestroy$)).subscribe((active) => {
      this.isLoading = active;
      this.crf.detectChanges();
    });

    this.filterService.getTopicsFilter().subscribe({
      next: (res) => {
        if (res.data) {
          this.filterDataStoreService.TopicsData = res.data;
        }
      }
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeOnDestroy$.next();
    this._unsubscribeOnDestroy$.complete();
  }


}
