import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { GlobalService } from './core/services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  // title = 'cmha-mb-newdev';

  // isLoading: boolean = true;

  // private _unsubscribeOnDestroy$: Subject<void> = new Subject();

  constructor(
    // private globalService: GlobalService,
    // private crf: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    // this.globalService.isActivatedLoader$.pipe(takeUntil(this._unsubscribeOnDestroy$)).subscribe((active) => {
    //   this.isLoading = active;
    //   this.crf.detectChanges();
    // });
  }

  ngOnDestroy(): void {
    // this._unsubscribeOnDestroy$.next();
    // this._unsubscribeOnDestroy$.complete();
  }

}
