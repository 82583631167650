export const SelfHelpresources=`<svg class="cl-icon" width="22" height="23" viewBox="0 0 22 23" fill="none"
xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2040_7824)">
  <path
    d="M10.0002 15C9.1008 15 8.2311 14.5827 7.55039 13.8254C6.88849 13.0868 6.48426 12.1018 6.41295 11.0527C6.33683 9.93363 6.66538 8.90454 7.33778 8.15454C8.01017 7.40455 8.95031 7 10.0002 7C11.0427 7 11.9855 7.41182 12.6557 8.16C13.3325 8.91545 13.6619 9.94272 13.5858 11.0523C13.5127 12.1027 13.1089 13.0873 12.4484 13.825C11.7694 14.5827 10.9001 15 10.0002 15ZM15.7675 23H4.23345C4.04801 23.001 3.86482 22.9578 3.69796 22.8737C3.53111 22.7897 3.38504 22.6669 3.27101 22.515C3.15005 22.3503 3.06652 22.1593 3.02667 21.9562C2.98682 21.7532 2.99168 21.5435 3.0409 21.3427C3.40925 19.8059 4.32182 18.5313 5.67975 17.6573C6.88499 16.8818 8.41921 16.4545 10.0002 16.4545C11.6123 16.4545 13.1063 16.8636 14.3186 17.6386C15.6795 18.5082 16.5934 19.79 16.96 21.3454C17.0086 21.5464 17.013 21.7561 16.9727 21.959C16.9324 22.1619 16.8485 22.3527 16.7273 22.5173C16.6134 22.6685 16.4676 22.7906 16.3013 22.8743C16.1349 22.958 15.9523 23.001 15.7675 23Z"
    fill="currentColor" />
  <path d="M18 2L18 8" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
  <path d="M21 5L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
</g>
<defs>
  <clipPath id="clip0_2040_7824">
    <rect width="22" height="22" fill="currentColor" transform="translate(0 0.5)" />
  </clipPath>
</defs>
</svg>`;