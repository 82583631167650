import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModulesRoutingModule } from './modules-routing.module';
import { RouterModule } from '@angular/router';
import { ModulesComponent } from './modules.component';
import { DataService } from '../core/services/data.service';


@NgModule({
  declarations: [ModulesComponent],
  imports: [
    CommonModule,
    RouterModule,
    ModulesRoutingModule
  ],
  providers: [DataService]
})
export class ModulesModule { }
