export const PeopleCircle = `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
<g clip-path="url(#clip0_1234_27786)">
  <path d="M11.5 23C5.15897 23 0 17.841 0 11.5C0 5.15897 5.15897 0 11.5 0C17.841 0 23 5.15897 23 11.5C23 17.841 17.841 23 11.5 23ZM11.5 1.76923C6.13702 1.76923 1.76923 6.13702 1.76923 11.5C1.76923 16.863 6.13702 21.2308 11.5 21.2308C16.863 21.2308 21.2308 16.8657 21.2308 11.5C21.2308 6.13425 16.8657 1.76923 11.5 1.76923Z" fill="currentColor"/>
  <path d="M15.5873 13.2763C14.6135 13.2763 13.6727 12.8446 12.9378 12.0612C12.2109 11.2734 11.7764 10.2605 11.7065 9.19129C11.6242 8.03372 11.9796 6.96842 12.7072 6.1928C13.4348 5.41717 14.4518 5 15.5873 5C16.7145 5 17.7348 5.42612 18.4602 6.20007C19.1855 6.97401 19.5499 8.04546 19.4676 9.1924C19.3964 10.2609 18.962 11.2731 18.2363 12.0612C17.5009 12.8446 16.5606 13.2763 15.5873 13.2763ZM6.64086 13.4642C4.97526 13.4642 3.51057 11.9258 3.37625 10.0346C3.30741 9.06602 3.60852 8.17073 4.22529 7.51365C4.84205 6.85658 5.69276 6.50707 6.6403 6.50707C7.58784 6.50707 8.43799 6.86721 9.05084 7.52148C9.66369 8.17576 9.97431 9.07721 9.90435 10.0379C9.76779 11.9264 8.30367 13.4642 6.64086 13.4642ZM20.9994 16.815C20.9104 16.5522 20.6939 16.272 20.2595 16.0064C18.9465 15.205 17.3313 14.7812 15.5873 14.7812C13.8764 14.7812 12.2164 15.2229 10.9123 16.0254C9.44318 16.9296 8.4559 18.2483 8.05797 19.8381C7.96394 20.2122 7.82682 20.9084 7.97346 21.2982C10.3266 22.1923 12.9191 22.2333 15.2994 21.4141C17.6798 20.5949 19.6971 18.9674 21 16.815H20.9994ZM6.63023 19.3924C7.02592 17.8149 7.86824 16.4996 9.17509 15.436C9.23687 15.3851 9.28385 15.3185 9.31113 15.2433C9.33841 15.168 9.34499 15.0869 9.33018 15.0082C9.31538 14.9296 9.27973 14.8563 9.22696 14.7961C9.17418 14.7359 9.10621 14.691 9.03013 14.666C8.35852 14.4518 7.59176 14.3371 6.64086 14.3371C5.30771 14.3371 3.89228 14.6978 2.82721 15.3532C2.52498 15.5394 2.22835 15.611 2 15.6748C2.9168 17.6993 4.43717 19.3915 6.35319 20.5198L6.45337 20.512C6.47528 20.1339 6.53452 19.7589 6.63023 19.3924Z" fill="currentColor"/>
</g>
<defs>
  <clipPath id="clip0_1234_27786">
    <rect width="23" height="23" fill="currentColor"/>
  </clipPath>
</defs>
</svg>
`;
