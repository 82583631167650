import { NgModule, ModuleWithProviders, Optional } from '@angular/core';
import { MeIconsComponent } from './me-icons.component';
import { Icons } from './me-icon.provider';

@NgModule({
  declarations: [MeIconsComponent],
  exports: [MeIconsComponent]
})
export class MeIconsModule {
  constructor(@Optional() private icons: Icons) {
    if (!this.icons) {
      throw new Error('No icon provided.');
    }
  }

  static pick(icons: { [key: string]: string }): ModuleWithProviders<MeIconsModule> {
    return {
      ngModule: MeIconsModule,
      providers: [{ provide: Icons, multi: true, useValue: icons }]
    };
  }
}
